<template>
    <div class="single-article__related">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <h2 class="single-article__related-title">Related articles</h2>

                    <div class="single-article__related-items">
                        <PartialsArticleListing
                            v-for="(article, index) in articles"
                            :key="index"
                            :title="article.title"
                            :date="article.date"
                            :slug="article.slug"
                            :excerpt="article.excerpt"
                            :featured_image="article.featured_image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        articles: {
            type: Object,
            required: true,
        },
    });

    const articles = ref([]);

    const getArticles = (items) => {
        // console.log(items);

        items.forEach(async (item) => {
            // console.log("Item: ", item);

            const { data } = await useFetch(`/api/wp/article/related/${item.ID}`);

            // console.log("Article Data: ", data.value);

            articles.value.push(data.value);
        });
    };

    await getArticles(props.articles);

    // console.log("Related Articles: ", props.articles);
</script>
